// Issues -- see _single-issues.scss
.single-issues {

    // need to offset for cover
    $padding: 20px;
    $v-offset: 30px;
    $h-offset: 0px;

    .issue-intro {
        padding-top: $v-offset;

        @include xs {
            margin-bottom: $section-spacing-mobile;
        }

        .content-box {
            position: relative;
            box-shadow: none;
        }
    }

    .intro-text {
        float: left;

        @include xs {
            height: auto !important; // reset JS
            float: none;
        }

        @include sm-and-up {
            width: 60%;
        }

        @include lg {
            width: 60%;

        }
    }

    .intro-cover-mobile {
        // border cause all the images have white padding
        // this makes it not look so out of whack with grid
        margin-bottom: 10px;
        @extend %shadowed;
        @include xs {
            display: inline-block !important; // have to override visible-xs
        }
    }

    .intro-cover {
        // we use JS to make the cover equal height to the text
        // then we use css to offset it in all directions
        // and then more JS to subtract from the left height
        width: 40%;
        float: right;
        margin-top: -1 * ($padding + $v-offset );
        margin-right: -1 * ($padding + $h-offset );
        margin-bottom: -1 * (2 * $padding + $v-offset);
        @extend %shadowed;

        @include sm-and-up {
            width: 40%;
        }

        @include lg {
            width: 40%;
        }

        img {
            width: 100%;
        }
    }

    .intro-summary {
        font-size: 14px;
        margin-bottom: 20px;
    }

    // Attempt to clean up styles of old posts
    .issue-details {
        h2 {
            font-family: $font-sans;
            font-size: 22px;
            line-height: 26px;
        }
        .post {
            // margin: 0;
        }
    }

}
