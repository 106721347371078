// .sidebar .related {
//     .ad {
//         margin-bottom: 15px;
//         img {
//             margin: 0 auto;
//             display: block;
//         }
//     }
// }
//
.related-posts {

    //prevent row for side scrolling view port
    // .row {
    //     margin-left: -10px;
    //     margin-right: -10px;
    // }
    .snippet-image-above .related-text {
    	padding: 10px 0;
    }
}
//
// .related-post {
//     margin-bottom: $section-padding;
//
//     .inner {
//         background-color: $white;
//     }
//
//     .related-text {
//         padding: 8px 5px;
//         @include sm-and-up {
//             padding: 15px;
//         }
//     }
//
//     .lead {
//         color: $gray-text-medium;
//         font-size: 11px;
//         font-weight: $font-bold;
//         line-height: 11px;
//         text-transform: uppercase;
//         margin-bottom: 0;
//
//         @include sm-and-up {
//             font-weight: $font-bold;
//             line-height: 33px;
//             text-transform: uppercase;
//         }
//     }
//
//     h2,
//     h3 {
//         margin-bottom: 0;
//     }
//
//     h2 {
//
//         color: $gray-text-dark;
//         font-size: 21px;
//         line-height: 21px;
//
//         @include sm-and-up {
//             font-size: 22px;
//             line-height: 25px;
//         }
//     }
//
//     h3 {
//         color: $gray-text-medium;
//         font-size: 12px;
//         line-height: 15px;
//
//         @include sm-and-up {
//             font-size: 13px;
//             line-height: 16px;
//             font-style: italic;
//         }
//     }
//
//
//     .arrow {
//         font-size: 14px;
//         font-weight: $font-bold;
//         line-height: 20px;
//
//         @include sm-and-up {
//             line-height: 22px;
//         }
//     }
//
//
//     .snippet-type {
//         color: $gray-text-medium;
//         font-size: 11px;
//         line-height: 11px;
//         font-weight: $font-bold;
//         letter-spacing: 0.1em;
//         .filmlinc-daily-logo {
//             max-width: 100px;
//             margin-bottom: 6px;
//         }
//     }
//
//     .snippet-title {
//         font-size: 22px;
//         line-height: 1.2;
//         margin-bottom: 0;
//         font-style: normal;
//         color: $gray-text-dark;
//     }
//
//     .snippet-meta {
//         color: $gray-text-medium;
//         font-size: 13px;
//         line-height: 1.2;
//         font-style: italic;
//         margin-bottom: 10px;
//     }
//
//     .snippet-cta {
//         font-size: 14px;
//         line-height: 1.5;
//         letter-spacing: 0.05em;
//     }
//     .snippet-image {
//         width: 100%;
//     }
//
// }
