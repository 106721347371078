.hero {
    position: relative;
    width: 100%;
    max-height: 1000px;
    overflow: hidden;
    img {
        width: 100%;
    }
    &:after {
        @include sm-and-up {
            content: "";
            background: transparent url('../img/fc-article-hero-gradient.png') repeat-x top center;
            position: absolute;
            bottom: 0;
            height: 480px;
            width: 100%;
        }
    }
}

.hero-text {
    margin: 30px auto 15px;
    color: $white;

    @include xs {
        width: 94%;
        margin-top: -10px;
        background-color: #f7f7f7;
        z-index: 99;
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    @include sm-and-up {
        text-align: center;
        position: absolute;
        width: 100%;
        z-index: 90;
        top: 50%;
        transform: translate(0, -50%);
        margin: 15px 30px;
    }

    h1 {

        font-family: $font-serif;
        color: $gray-dark;
        text-align: left;
        @include sm {
            font-size: 36px;
            line-height: 40px;
            width: 70%;
            margin: 0 auto 10px;
            color: $white;
            text-shadow: 0px 0px 30px rgba(150, 150, 150, 0.64);
            margin: 0 auto 10px;
            text-align: center;
        }

        @include md-and-up {
            font-size: 56px;
            line-height: 64px;
            width: 70%;
            margin: 0 auto 10px;
            color: $white;
            text-shadow: 0px 0px 30px rgba(150, 150, 150, 0.64);
            text-align: center;
        }
    }

    .prehead {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.15em;
        font-size: 14px;
        line-height: 20px;
        font-weight: $font-bold;
        text-align: left;
        color: $gray-dark;
        @include sm-and-up {
            text-align: center;
            color: $white;
        }
    }

    .subhead {
        font-weight: $font-semibold;
        color: $gray-dark;
        // color: $white;
        text-shadow: 0px 0px 30px rgba(150, 150, 150, 0.64);
        @include sm-and-up {
            width: 70%;
            margin: 0 auto 20px;
            color: $white;
        }

        @include md-and-up {
            width: 70%;
            margin: 0 auto 20px;
            font-size: 18px;
            line-height: 22px;
        }
    }

    .cta {
        color: $white;
        font-size: 20px;
        line-height: 26px;
        font-weight: bold;
        text-shadow: 0px 0px 30px rgba(150, 150, 150, 0.64);
        text-transform: uppercase;
        letter-spacing: 0.1em;
        @include sm {
            font-size: 18px;
            line-height: 20px;
        }
        @include xs {
            color: $blue;
            font-size: 16px;
            line-height: 20px;
            margin-top: 20px;

        }
    }
}

// this is a body class that makes the header transparent (except for the ads)
// and positions the navigation on top of a background image
.header-over-hero {
    $fc-header-height: 125px;
    .hero {
        min-height: $fc-header-height; // make sure the header peeks through
    }

    .hero-text {
        @include sm-and-up {
            // adjust vertical center for the height of the header
            margin-top: $fc-header-height / 2;
        }
    }
}
