// layout
$section-spacing-long: 60px;
$section-spacing: 30px; //more module spacing in mobile
$section-spacing-mobile: 15px;

$section-padding: 30px; //when spacing doesn't vary between mobile and desktop

// type
$font-serif: 'minion-pro', 'Georgia', serif;
$font-sans: 'AvenirWeb', 'Helveltica Neue', Helvetica, Arial, sans-serif;
$font-headline: $font-serif;

//font-weight (built into typekit)
$font-light:         300;
$font-regular:       400;
$font-semibold:      700; // No more semibold
$font-bold:          700;


// colors
$white:     #ffffff;
$white-off: #f7f7f7;
$black:     #000000;
$black-off: #404040;
$blue:      #024d9a;
$red:       #af2022;
$fc-gold:   #d8a27a;
$fc-gold-light: #f89a70;

//grays
$gray-dark:             #1e1e1e;
$gray-medium:           #bbbbbb;
$gray:                  #e5e5e5;
$gray-lighter:          #eaeaea;
$gray-lightest:         #f7f7f7;

$gray-text-dark:        #333333;
$gray-text-medium:      #787878;
$gray-text-light:       #cdcdcd;

// delete:
$orange: $blue;

//
// navbar
//

// set navbar breakpoint up to md
// $grid-float-breakpoint:                     $screen-sm-min;

$navbar-default-bg:                         $white;
$navbar-default-border:                     none;
$navbar-border-radius:                      0;
$nav-tabs-border-color:                     transparent;

// transitions

$transition-link:          .1s ease;
$transition-link-hover:    .2s ease;

$transition-button:          .1s ease;
$transition-button-hover:    .2s ease;

//
// bootstrap vars -- customize bootstrap here, leave vendor files untouched
//

$body-bg:                   $white-off;
$font-family-sans-serif:    $font-sans;
$font-family-serif:         $font-serif;
$font-family-base:          $font-sans;
$headings-font-family:      $font-serif;
$font-size-base:            16px;
$text-color:                $black-off;
$headings-color:            $black-off;

$btn-border-radius-base:    0;
$btn-border-radius-large:   0;
$btn-border-radius-small:   0;
$btn-primary-bg:            $red;

$input-border-radius:       0;
$input-border-radius-large: 0;
$input-border-radius-small: 0;

$brand-primary:             $blue;
$link-color:                $blue;

$grid-gutter-width:         40px;
$container-large-desktop:   1330px;
$screen-lg:                 1330px;

// slick

//magnific
$mfp-inner-close-icon-color : $gray;