/**
 * @license
 * MyFonts Webfont Build ID 3279210, 2016-09-06T09:59:03-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: AvenirLTStd-Heavy by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy/
 * 
 * Webfont: AvenirLTStd-Oblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-oblique/
 * 
 * Webfont: AvenirLTStd-Roman by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-roman/
 * 
 * Webfont: AvenirLTStd-HeavyOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy-oblique/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3279210
 * Licensed pageviews: 250,000
 * Webfonts copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 * 
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/32096a");

// Heavy
@font-face {
	font-family: 'AvenirWeb';
	font-weight: bold;
	font-style: normal;
	src: url('../fonts/32096A_0_0.eot');
	src: url('../fonts/32096A_0_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/32096A_0_0.woff2') format('woff2'),
	url('../fonts/32096A_0_0.woff') format('woff'),
	url('../fonts/32096A_0_0.ttf') format('truetype');
}
 
// Roman Italic  
@font-face {
	font-family: 'AvenirWeb';
	font-weight: normal;
	font-style: italic;
	src: url('../fonts/32096A_1_0.eot');
	src: url('../fonts/32096A_1_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/32096A_1_0.woff2') format('woff2'),
	url('../fonts/32096A_1_0.woff') format('woff'),
	url('../fonts/32096A_1_0.ttf') format('truetype');
}
 
// Roman  
@font-face {
	font-family: 'AvenirWeb';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/32096A_2_0.eot');
	src: url('../fonts/32096A_2_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/32096A_2_0.woff2') format('woff2'),
	url('../fonts/32096A_2_0.woff') format('woff'),
	url('../fonts/32096A_2_0.ttf') format('truetype');
}
 
// Heavy Italic  
@font-face {
	font-family: 'AvenirWeb';
	font-weight: bold;
	font-style: italic;
	src: url('../fonts/32096A_3_0.eot');
	src: url('../fonts/32096A_3_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/32096A_3_0.woff2') format('woff2'),
	url('../fonts/32096A_3_0.woff') format('woff'),
	url('../fonts/32096A_3_0.ttf') format('truetype');
}