//
// common styles across basic single entries
//

.page-container {
    min-height: 40vh; // a little space on super short pages (like 404)
}

// main content area
.content-container {
    background: $white;
    padding-top: $section-padding;
    padding-bottom: $section-padding;
    @extend .module;
}

.post {

    margin-left: $grid-gutter-width / 2;
    margin-right: $grid-gutter-width / 2;

    @include md-and-up {
        margin-left: 1/12 * $container-large-desktop;
        margin-right: 1/12 * $container-large-desktop;
    }

    &.bordered {
        border-top: 15px solid $black;
        padding-top: 0;
        margin-top: 60px;
        @include xs {
            margin-top: 0px;
        }
        &:before {
            content: '';
            display: block;
            height: 5px;
            border-bottom: 1px solid $black;
        }
        h1 {
            margin-top: 30px;
        }
    }

    .post-title-meta {
        font-weight: bold;
        letter-spacing: 0.1em;
        color: $gray-text-medium;
    }

    .post-shares {
    }

    .post-meta {
        color: $black-off;
        margin-bottom: 30px;
        font-family: $font-sans;
        font-weight: $font-semibold;
        font-size: 14px;

        .sub {
            // font-size: 12px;
        }
    }

    .post-content {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .post-feature {
        img {
            width: 100%;
        }
    }

    .post-footer {
        font-family: $font-sans;
    }
}

.post-content {
    img {
        height: auto !important;
    }
    .wp-caption {
        width: auto !important;
    }

}
//
// Post type specific
//

// Blog

.single-post {
    h1 {
        margin-top: 30px;
    }
}

// Articles -- see _single-articles.scss
// Issues -- see _single-issues.scss

.single {
    @include md-and-up {
        .content-wrap {
            width: calc(91.66667% - 340px);
        }
        .sidebar {
            max-width: 320px;
            padding: 0;
            padding-left: 20px;
        }
    }
}