.template-events {
	.program, .press, .upcoming-events {
		li {
			clear: both;
			margin-bottom: 20px;
			@include clearfix;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 30%;
				float: left;
			}
			p {
				width: 65%;
				float: right;
			}
			&.event {
				p {
					margin: 0;
				}
				.date {
					font-size: 14px;
					font-style: italic;
				}
			}
		}
	}
	.caption {
		font-size: 14px;
		font-style: italic;
		margin-top: 5px;
	}
	.content-box {
		@include clearfix;
	}
	.programs-list {
		margin: 30px 0;
		h3 {
			margin: 15px 0 15px;
		}
	}
}
