// As much as possible, set type by customizing the default bootstrap variables in _variables.scss

html {
    -webkit-font-smoothing: antialiased;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and (min-device-pixel-ratio: 1.25),
only screen and (min-resolution: 200dpi),
only screen and (min-resolution: 1.25dppx)
{
    -webkit-font-smoothing: subpixel-antialiased;
}


// The two main fonts
%font-sans {
    font-family: sans-serif;
}

%font-headline {
    font-family: $font-headline;
    text-transform: uppercase;
}

//
// default heading styles (can apply anywhere on site)
//

// typical interior header tag

h1 {
    color: $black-off;
    font-weight: $font-regular;
    font-size: 29px;
    line-height: 1.3;
    font-weight: bold;

    @include sm-and-up {
        font-size: 38px;
        line-height: 1.2;
    }
}

// typical block section header

h3 {
    color: $text-color;
    font-family: $font-sans;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: 0.1em;
    margin: 30px 0 15px;

    a,
    a:hover {
        color: $text-color;
        text-decoration: none;
    }
}

// Snippet titles
h4 {
    color: $text-color;
    font-family: $font-sans;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;

    a,
    a:hover {
        color: $text-color;
        text-decoration: none;
    }
}

// this content class is applied to all large blocks of text
// (typically entered into a wysiwyg by an editor)

.content {

    p {
        @include sm-and-up {
            font-size: 16px;
            line-height: 24px;
        }
    }

    // uniform bottom margin (including divs which act like p tags)
    p,
    .entry-content-asset,
    .snippet-inline,
    .wp-caption {
        @include paragraph-spacing();
    }
}


.lead {
    color: $gray-text-medium;
    font-size: 16px;
    font-weight: 700;
}

//a link that appears in a stack with others
.stack-link {
    font-size: 22px;
    line-height: 1.2;

    @include sm-and-up {
        font-size: 18px;

        .secondary & {
            font-size: 16px;
        }
    }
}

.by-line {
    font-size: 16px;
    line-height: 1.3;

    @include sm-and-up {
        font-size: 14px;
    }
}


//common type styles found

%prominent-small-type {
    font-size: 9px;
    line-height: 33px;

    @include sm-and-up {
        font-size: 13px;
    }
}

%prominent-medium-type {
    font-size: 14px;
    line-height: 17px;

    @include sm-and-up {
        font-size: 14px;
        line-height: 18px;
    }
}

// Body type styles for posts
.post-content, .post {
    font-family: $font-serif;
    ul, ol {
        // margin: 30px;
        // font-size: 16px;
        // line-height: 22px;
        ul, ol {
            margin: 15px;
        }
        li {
            margin: 5px 0;
        }
    }
    ul {
        li {
            list-style-type: disc;
        }
    }

    table {
        background: $gray;
        padding: 30px;
        width: 100%;
        margin: 30px 0;
        th {
            padding: 15px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            margin-bottom: 15px;
        }
        td {
            padding: 5px 15px;
        }
    }
     h4 {
        font-weight: bold;
     }
     h5 {
        font-weight: bold;
     }
}

.left-quote {
    border: 0;
    padding: 0;
    position: relative;

    &:before {
        color: $gray-medium;
        font-style: italic;
        font-family: $font-sans;
        content: '"';
        font-size: 48px;
        float: left;
        position: absolute;
        top: -10px;
        left: -30px;
    }

}
