// Related content inside of a single post body
.snippet-post-type {

}
.snippet-image-above {

    font-family: $font-sans;

    .snippet-image {
        width: 100%;
        margin-bottom: 4px;
    }
    .date {
        font-style: italic;
        margin-bottom: 8px;
        font-size: 13px;
    }

    .snippet-post-type {
        font-size: 11px;
        font-style: italic;
        color: #474747;
        span {
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: 0.1em;

        }
    }

    .snippet-title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 0;
        font-style: normal;
        color: $blue;
        margin-bottom: 2px;
        font-weight: $font-light;
        a,
        a:hover {
            color: $blue;
        }

    }

    .snippet-meta {
        color: $gray-text-medium;
        font-size: 13px;
        line-height: 1.2;
        font-style: normal;
        margin-bottom: 10px;

        a {
            color: $red;
        }
        @include xs {
            font-size: 16px;
        }
    }

    .snippet-text {
        font-size: 14px;
        @include xs {
            font-size: 16px;
        }
    }

    // variations

    &.white-bg {
        background: $white;

        .related-text {
            padding: 10px 20px;
        }
    }
    .sidebar & {
        .snippet-title {
            font-size: 18px;
            line-height: 22px;
            @include xs {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
    .hero-footer & {
        .snippet-title {
            font-size: 16px;
            line-height: 20px;
        }
        .snippet-text {
            font-size: 12px;
            line-height: 16px;
        }
        .snippet-meta {
            font-size: 12px;
            line-height: 16px;
        }

    }
}
