//Small social snippets (share links, ..)

.share-links {
    font-style: normal;
    >* {
        float: left;
        margin-right: 5px;
    }

    span {
        font-size: 12px;
        line-height: 1.7;
        font-weight: $font-bold;
    }

    a {
        margin-right: 5px;
        color: $gray-text-dark;
    }
}

.social-share-vertical {

    // .shareaholic-share-buttons {
    //     border: 1px solid lime;

    //     .sharebuttons {
    //         display: block !important;
    //     }
    // }

    .share-buttons {
        text-align: right;

        a {
            background: #eaeaea;
            color: #1e1e1e;
            padding: 7px 11px;
            position: relative;
            text-decoration: none;
            margin-bottom: 5px;
            display: inline-block;
            text-align: center;

            i {
                font-size: 24px;
                line-height: 24px;
                width: 20px;
                display: inline-block;
                position: relative;
                top: 3px;
            }

            &:hover, &:focus {
                text-decoration: none;
                background: darken($gray-lighter,10%)
            }
        }
    }

}
