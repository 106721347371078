// Text on top of thumb, used on NYFF

.snippet-overlay {
    position: relative;

    a:hover {
        text-decoration: none;
    }
    .overlay-headline {
        color: $white;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;
    }
    .overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: $grid-gutter-width / 2;
    }
    @include sm-and-up {
        .overlay-headline {
            font-size: 22px;
            line-height: 26px;
            margin: 0;
        }
    }

    .secondary-features & {
        .overlay-headline {
            color: $white;
            font-size: 26px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: 400;

            @include xs {
                margin: 30px 0 60px;
            }
        }

        @include xs {
            .overlay {
                position: relative;
                padding: 0;
            }
        }
    }
}
