//
// This sets up rotators
// actual hero classes in hero.scss
//

.slick-slider {
    padding: 0;
}

.rotator {
    position: relative;

    .direction-nav-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;

        .direction-nav {
            position: relative;
            height: 100%;
            width: 95%;
        }
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        padding: $grid-gutter-width / 2;
        margin-top: -1 * ( ( $grid-gutter-width / 2 ) + ( 64 / 2 ) ); // offsets for padding and font
        line-height: 1;
        cursor: pointer;
        color: rgba($white, .5);
        &:before {
            position: absolute;
            top: 66%;
            font-size: 32px;
            font-family: "icomoon";
        }
        @include sm-and-up {
            &:before {
                top: 50%;
                font-size: 64px;
            }
        }
    }

    .slick-prev {
        left: 0;
        text-align: left;
        &:before {
            content: $fa-chevron-left;
        }
    }

    .slick-next {
        right: 0;
        text-align: right;
        &:before {
            content: $fa-chevron-right;
        }
    }

    &.home-hero {
        .direction-nav-container {
            // the homepage has varying heights for the slides, so we use JS to equalize the height
            height: auto;
        }
    }
}
