//== Buttons

// Fix bootstrap default active funkiness
.btn {
    &:focus {
        background: inherit;
    }
}

.btn {
    // @include transition-link;

    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 10px 20px;
    border-radius: 0;
	&:focus {
	  	background: #000;
	}
    .sidebar & {
        width: 100%;
        white-space: normal;
        line-height: 1;
    }
}
