.archive-nav {
    float: left;
    width: 100px;

    @include xs {
        display: none;
    }

    li {
        font-size: 14px;
        text-align: right;
        padding: 5px 25px 5px 10px;
        font-weight: $font-semibold;

        a {
            color: $gray-text-medium;
            text-decoration: none;
            &:hover {
                color: $gray-text-dark;
            }
        }

        &.header {
            font-size: 13px;
            font-weight: bold;
            text-align: left;
            text-transform: uppercase;
        }

        &.active {
            background: $gray-lighter;
        }
        &:hover {
            background: $gray-lighter;
        }
    }
}

.archive-content {
    $row-spacing: 20px;

    float: left;
    width: calc(100% - 100px);
    background: $white;
    padding: 30px; // 10px less than normal



    @include xs {
        width: 100%;
    }

    .issue-container {
        margin-bottom: $row-spacing;
    }

    .issue {
        padding-left: $row-spacing / 2;
        padding-right: $row-spacing / 2;
        // margin-bottom: 20px;

        .row:last-child & {
            margin-bottom: 0;
            @include xs {
                margin-bottom: 20px;
            }
        }

        .issue-thumb {
            position: relative;
            display: block;
            background: $black;
            &.active,
            &:hover {
                .overlay {
                    display: block;
                }
            }

            &.active {
                margin-top: 10px;
                img {

                    opacity: 0.8;
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                background-color: rgba($black, .2);
                z-index: 200;
                cursor: pointer;
                &:hover {
                    display: block;
                }
            }
        }

    }

    .issue-text {
        margin-top: -1 * $row-spacing;
        margin-bottom: $row-spacing;
        border-top: 5px solid $gray-lighter;
        background: $white-off;
        background: $gray-lightest;
        padding: 0;

        .issue-text-desc {
            float: left;
            width: 60%;
            padding: $grid-gutter-width;

            .issue-closer {
                color: $text-color;
                text-decoration: none;
                padding: 10px;
                position: relative;
                left: -10px;
                &:hover {
                    color: $black;
                }
            }

            h3 a {
                color: $link-color;

            }
        }

        .issue-cover {
            float: right;
            width: 40%;
        }
    }

}

.archive {
    .fc-pagination {
        @include sm-and-up {
            margin-left: 100px; // center beneath content
        }
    }
    .hero-text {
        @include sm-and-up {
            top: auto;
            bottom: 30px;
        }
        h1 {
            font-size: 56px;
            line-height: 60px;
            @include sm {
                // font-size: 56px;
                // line-height: 60px
            }
            @include md-and-up {
                font-size: 96px;
                line-height: 100px;
            }
        }
        .subhead {
            @include sm {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .hero {
        @include sm-and-up {
            height: auto;
            max-height: 500px;
            overflow: hidden;
        }
        @include md {
            height: auto;
            max-height: 650px
        }
        @include lg {
            height: auto;
            max-height: 940px;
        }
    }
}


.archive-select {
    margin: 15px;
    h3 {
        display: inline;
        text-transform: uppercase;
    }
    select {
        display: inline;
    }
}


// search version of archive

.form-search-results {

    input { color: $text-color };

    .btn {
        background: transparent;
        border: none;
        padding-left: 10px;
        padding-right: 0px;

        &:hover {
            color: $link-color;
        }
    }
}
