@media print {
    // hide links
    .logo-normal:after,
    .logo-reverse:after,
    {
        display: none;
    }
    .hero img, .page-container img, .masthead, .social-share-vertical, .issue-pull {
	   display: none;
	}
	.logo-nav {
		.header-logo img {
			width: 300px;
			margin-bottom: 30px;
		} 		
	}

	h1 {
		font-size: 45px !important;
	}
	.post.bordered {
		margin: 0 !important;
		border: 0 !important;
	}
	.single-articles .content-container {
		padding: 0 !important;
	}
	.post {
		margin: 0 !important;
	}

	.page-container {
		
	}
 }
