
// Bottom margin only

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
    margin-top: 0;

    // last element shouldn't have margin to prevent doubling up
    // This is causing issues when there's an h3 element alone in a container, commenting out for now.
    &:last-child {
        // margin-bottom: 0;
    }
}

//spacing between big items on page (modules)
.module {
    margin-bottom: $section-spacing-mobile;

    @include sm-and-up {
        margin-bottom: $section-spacing;
    }
}

%section-padding {
    padding: $section-spacing-mobile;

    @include sm-and-up {
        padding: $section-spacing-long $section-spacing;
    }
}

%section-small-padding {
    padding: 15px 10px;
}

@mixin paragraph-spacing() {
    margin-bottom: 20px;
}


.section-padded {
    padding-top: $section-spacing-mobile;
    padding-bottom: $section-spacing-mobile;

    @include sm-and-up {
        padding-top: $section-spacing;
        padding-bottom: $section-spacing;
    }
}

// used most often on sidebar blocks
// always apply to an element inside of a col-*, not on the col itself
.padded-block {
    padding: $grid-gutter-width / 2;
}

//stretch a div (e.g.: an image holder) end to end
.unpadded-horizontally {
    margin-left: -1 * $section-padding;
    margin-right: -1 * $section-padding;
}


//in mixin form
@mixin vertical-margin($v-margin: $section-padding) {
    margin-bottom: $v-margin;

    &:last-child {
        margin-bottom: 0;
    }
}

//remove gutters between columns
.no-gutter  {
    margin-left: 0;
    margin-right: 0;
    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}

//place on parent
.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -ms-flex-pack: center;
}

//ie fix. prevent text breaking out of flex
.flex-centered>p, .flex-centered>div, .flex-centered>h2 {
    width: 100%;
}

.flex-centered-sm {
    @include sm-and-up {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        -ms-flex-pack: center;
    }
}


.table-centered {
    display: table;
    margin: auto;
}
