
.subscriptions-header {
    background: #f7f7f7;
    img {
        margin-bottom: 20px;
        @include sm-and-up {
            margin-bottom: 0;
        }
    }
}

.subscriptions-on-offer {

    h2 {
        color: #454545;
        font-size: 42px;
        font-weight: $font-bold;
    }

    .on-offer-text {
        .blurb {
            margin-bottom: 20px;
        }

        .btn-primary {
            margin-bottom: 20px;
        }
    }

    .offer {
        padding: 0;
        position: relative;
        .label-and-highlighted-text {
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-sans;
            font-weight: $font-bold;
        }
        .label {
            display: inline-block;
            padding: 6px 12px;
            color: $white;
            margin-right: 4px;
            font-size: 15px;
            background-color: #024d9a;
        }

        .highlighted-text {
            display: inline-block;
            color: #024d9a;
            font-size: 12px;
            position: relative;
            top: 5px;
            line-height: 1;
        }
        .offer-text {
            padding: 40px 0;
        }

        .offer-title {
            color: #454545;
            font-family: $font-sans;
            font-weight: 800;
            font-size: 20px;
            @include sm-and-up {
                font-size: 36px;
            }
        }

        .offer-subtitle {
            margin-bottom: 10px;
            font-size: 14px;
            @include sm-and-up {
                font-size: 18px;
            }
        }

        .btn {
            font-size: 11px;
            padding: 5px 10px;
            @include sm-and-up {
                font-size: 12px;
            }
        }

        img {
            @include xs {
                max-width: 75%;
            }
            @include xs-wide {
                max-width: 90%;
            }
            @include lg {
                max-width: 90%;
            }
        }
    }
}

.subscriptions-flyer {
    background-color: #5167a5;
    color: $white;

    .title {
        color: $white;
    }
}

//template specific
.page-template-template-subscriptions {

    //spacing
    .subscriptions-on-offer, .subscription-notes {
        padding: 25px 0;
        @include sm-and-up {
            padding: 50px 0;
        }
    }

    .container {
        margin-bottom: 0;
    }

    .quotes-holder {
        padding: 10px 0 20px;
    }

    .quote-text {
        font-family: $font-sans;
        color: #454545;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .quote-source {
        color: $red;
        font-size: 12px;
        font-weight: 900;
    }

    .subscriptions-flyer {
        overflow: hidden;
        position: relative;

        .spacer {
            position: absolute;
            top: 0;
            width: 100%;
            height: 30px;
            background-color: $white;
        }

        .title {
            font-family: $font-sans;
            font-weight: $font-bold;
            line-height: 1.3;
            font-size: 20px;
            @include sm-and-up {
                font-size: 27px;
            }
        }
        .blurb {
            font-family: $font-serif;
            line-height: 1.5;

            font-size: 16px;
            @include sm-and-up {
                font-size: 22px;
            }

            a {
                color: $fc-gold;
            }
        }

        .flyer-text-col {
            padding: 30px 0 30px;
            @include sm-and-up {
                padding-top: 60px;
            }

            .container {
                @include sm-and-up {
                    width: 100%;
                }
            }
        }
        .flyer-text {
            @include sm-and-up {
                padding: 30px;
            }
        }

        img {
            @include sm-and-up {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        @include xs {
            padding: 20px 30px;
            .spacer {
                display: none
            }
            .flyer-img {
              position: relative;
              margin-bottom: -100px;
              right: 0px;
              top: auto;
            }
        }
        @include sm-and-up {
            padding: 100px 0;
            .flyer-img {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}
