// simple horizontal array of images. six in a row , three in mobile
.six-up-collage {
    overflow: hidden;

    .inner {
        display: flex;
        flex-wrap: wrap;
    }

    .collage-thumb {
        position: relative;
        box-shadow: 0 0 21px rgba(0, 0, 0, 0.16);
        margin-right: 10px;
        flex-grow: 1;
        width: calc(100% * (1/3) - 10px);
        @include xs-wide-and-up {
            width: calc(100% * (1/6) - 10px);
        }
    }
}
