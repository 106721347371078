// styles for all articles
.single-articles {
    .post {
        @include lg {
            margin: 0 120px;
        }

        @include md {
            margin: 0 80px;
        }

        @include sm {
            margin: 0 40px;
        }
    }
    .content-container {
        padding-top: 40px;
        @include md-and-up {
            padding-top: 80px;
        }
    }
    .post-meta {
        margin-top: 20px;
    }
}

// Issue callout

.issue-callout {
    font-family: $font-sans;
    background: $gray-lighter;
    margin-bottom: 20px;
    width: 100%;

    .inner {
        padding: 10px;
    }

    .cover {
        margin-bottom: 10px;
        @include xs {
            margin: 0 auto 10px;
        }
    }

    .issue-desc {
        a {
            color: $red;
            @include sm-and-up {
                display: block;
            }
        }
    }

    .issue-also {
        text-transform: uppercase;
        font-size: 9px;
    }

    .item {
        font-size: 12px;
        margin-bottom: 10px;

        .title {
            display: block;
            color: $red;
        }
    }
    .btn {
        white-space: normal;
        line-height: 12px;
    }

    // variation that is floated
    &.issue-pull {
        @include sm-and-up {
            width: 200px;
            float: right;
            margin-left: 20px;
        }
    }

    // variation that is full width (float cover left)
    &.issue-full-width {
        @include sm-and-up {
            .cover {
                float: left;
                max-width: 180px;
            }

            .issue-text {
                margin-left: 200px;
            }
        }
    }
}

// the simple layout
.article-simple-layout {
    .subhead {
        font-weight: $font-semibold;
        color: $gray-dark;
        font-size: 18px;
        line-height: 22px;
    }

    // some posts start with images, but we need to make way for the issue callout
    // .post-content {
    //     p:first-of-type {
    //         img {
    //             @include md {
    //                 max-width: 300px;
    //             }
    //             @include lg {
    //                 max-width: 400px;
    //             }
    //         }
    //     }
    // }
}

// the complex layout
.article-flex-layout {
    .hero-image {
        @include sm-and-up {
            &:before {
                    content: "";
                    background: transparent url('../img/hero-bg-gradient.png') repeat-x top center;
                    position: absolute;
                    top: 0;
                    height: 500px;
                    width: 100%;
            }
            &:after {
                    content: "";
                    background: transparent url('../img/fc-article-hero-gradient.png') repeat-x top center;
                    position: absolute;
                    bottom: 0;
                    height: 480px;
                    width: 100%;
            }
        }
    }
    .hero-text {
        margin-top: 0;
        @include sm-and-up {
            top: auto;
            bottom: 0px;
        }
        h1 {
            font-size: 56px;
            line-height: 60px;
            @include sm-and-up {
                text-shadow: 0px 0px 10px rgba(15, 15, 15, 0.52);
            }
            @include md-and-up {
                font-size: 80px;
                line-height: 80px;
            }
        }
        .subhead {
            line-height: 1em;
            @include sm {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .content-container {
        @include sm-and-up {
            margin-top: -100px; // raise into hero area
        }
    }

    .flex-post.first {
        p:first-of-type {
            @include sm-and-up {
                // font-family: $font-serif;
                // font-size: 22px;
                // line-height: 26px;
            }
            @include md {
                // font-size: 32px;
                // line-height: 36px;
            }

            @include md-and-up {
                // font-size: 32px;
                // line-height: 36px;
                .drop {
                    font-family: $font-sans;
                    font-weight: bold;
                    display: inline-block;
                    float: left;
                    margin-top: 0;
                    margin-right: 10px;
                    font-size: 90px;
                    line-height: 72px;
                }
            }
        }
    }

    .flex-image-full {
        margin: 40px 0;
        // if last element, remove container padding (empty white space)
        &.last {
            margin-bottom: -30px;
        }
    }

    .flex-image-breakout {
        // this ensures full width, but badly distorts
        // margin-left: -100vh;
        // width: 200vw;
        //margin-left: -33%;
        width: 100vw;
        background-size: cover;
        background-position: center center;
        @include xs { height: 350px; }
        @include sm { height: 350px; }
        @include md { height: 600px; }
        @include lg { height: 800px; }

        // if last element, remove container padding (empty white space)
        &.last {
            margin-bottom: -30px;
        }
    }

    // placed above/below breakout items
    .peaky-below {
        width: 100%;
        height: 50px;
        background: $white;
        position: relative;
        bottom: -50px;
        margin-top: -70px;
        z-index: 2;
        @include xs {
            // display: none;
        }
    }

    .peaky-above {
        width: 100%;
        height: 50px;
        background: $white;
        position: relative;
        top: -50px;
        margin-bottom: -70px;
        z-index: 2;
        @include xs {
            display: none;
        }
    }
    .peaky-above + .post.flex-post {
      margin-top: 50px;
    }
    .quote {
        position: relative;
        padding: 45px 20px;
        background: $white;
        font-style: italic;
        margin: 30px 0;
        box-shadow: -7px 0px 8px -6px rgba(0,0,0,0.1);
        @include sm-and-down {
            margin: 30px;
            width: auto;
            box-shadow: none;
            padding: 0;
        }
        @include xs {
            margin: 30px;
        }
        p {
            font-size: 18px;
            line-height: 26px;
            margin: 0;
            width: 80%;
            float: left;
            &:after {
                content: '\201D';
            }
            @include sm-and-down {
                float: none;
                width: 100%;
            }
        }
        .quote-open {
            content: '\201C';
            font-family: $font-headline;
            font-size: 112px;
            color: #ccc;
            font-style: normal;
            float: left;
            line-height: 100%;
            margin-right: 10px;
            margin-right: 2%;
            width: 17%;
            @include sm-and-down {
                float: none;
                width: 100%;
                line-height: 0.1em;
                margin-top: 30px;
            }
        }


    }
}


// .flex-image-breakout.breakout-full-width.wp-caption:before {
//     background: -webkit-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
//     background-image: -moz-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
//     background-image: -o-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
//     background-image: linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
//     background-image: -ms-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );
//     content: "";
//     position: relative;
//     height: 100px;
//     width: 1000px;
//     display: block;
//     top: -100px;
// }