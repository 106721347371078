
.fc-pagination {
    .pagination {
        li {
            margin: 0 2px;
            display: inline-block;
        }
        a, span {
            color: $gray-text-medium;
            border: none;
            background: transparent;
            font-size: 12px;
            padding: 4px 8px;
        }
        .current {
            color: $gray-text-medium;
            background: $gray-lighter;
        }
    }
}
