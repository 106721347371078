.main-header {
    background-color: $white;

    @include xs {
        margin-bottom: 0;
    }

    a {
        transition: $transition-link;
    }

    // reset bootstrap
    .navbar {
        background-color: inherit;
        border: none;
        border-radius: 0;
        margin-bottom: 0;
        min-height: initial;
        margin-top: 10px;
    }

    .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
    .desktop-search, .desktop-sign-up {
        @include xs {
            display: none;
        }
        background: $gray-lighter;
        input {
            background: transparent;
            border: 0;
            color: $gray-dark;
        }
        input[type=text] {
            width: 80%;
        }
        .input-group-btn {
            width: 40px;
            float: right;
            margin-right: 10px;
        }
        .btn {
            background: transparent;
            border: 0;
        }
    }
    .updates-form {

        padding: 10px;
        legend {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
            display: inline;
            font-style: normal;
        }
        .wrap {
            background: $gray-lighter;
        }
        input {
            display: inline;
        }
        .email-address {
            width: 80%
        }
        input[type=submit] {
            float: right;
            width: 20%;
            text-align: right;
            color: $red;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
        }
        form {
            margin-bottom: 0;
        }
    }
}

.header-app-banner {
    background-color: $black;
}

.header-ads {
    border-bottom: 1px solid $gray-lightest;
    background: #333333;

    @include sm-and-up {
        padding: 10px $grid-gutter-width / 2;
    }

    @include lg {
        padding: 15px $grid-gutter-width / 2;
    }

    // force responsive to adjust
    .ad-billboard {
        @include breakpoint-max(1024px) {
            overflow: hidden; //prevent add widget iframes breaking out of container
            height: 90px !important;
            width: 728px !important;
        }
    }

    // force responsive to adjust
    .ad-leaderboard, .ad-billboard {
        @include xs {
            height: 50px !important;
            width: 320px !important;
        }
        > div {
            @include xs {
                height: 50px !important;
                width: 320px !important;
            }
        }
        .ad {
            margin-bottom: 0;
        }
    }
}

// row above logo/navbar

.masthead {

    // &:before {
    //     content: "";
    //     display: block;
    //     border-top: 1px solid $gray-lightest;
    //     margin-right: $grid-gutter-width / 2;
    //     margin-left: $grid-gutter-width / 2;
    //     margin-bottom: 5px;
    // }

    &:after{
        content: "";
        display: block;
        border-bottom: 2px solid $gray;
        margin-right: $grid-gutter-width / 2;
        margin-left: $grid-gutter-width / 2;
        margin-top: 10px;
    }

    .header-subhead {
        font-size: 14px;
        font-weight: bold;
        line-height: 30px;
        a {
            color: $gray-dark;
            &:hover {
                color: $link-color;
                text-decoration: none;
            }
        }
        @include sm {
            font-size: 12px;
        }
    }

    .header-secondary-nav {

        a {
            @include transition-link;
            color: $black;
            &:hover {
                color: $link-color;
                text-decoration: none;
            }
        }
        li.active {
            background: $gray-lighter;
        }

        .icon {
            margin-right: 15px;
        }

        .secondary-nav {
            display: inline;
            line-height: 30px;
            a {
                font-weight: bold;
                font-size: 14px;
                @include sm {
                    font-size: 12px;
                }
            }
        }

        .social-links {
            margin-left: 10px;
            .icon:last-child {
                margin-right: 0px;
            }
        }
    }
}

// row with logo and nav bar

.logo-nav {

    padding-top: 10px;
    padding-bottom: 10px;

    @include sm-and-up {

        &>div:last-child {
            padding-left: 0;
        }
    }

    .navbar {
        width: 100%;
    }

    .header-logo {
        @include xs {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img {
            max-width: 260px;
            width: 100%;
            @include sm-and-up {
                max-width: 400px;
            }
        }

        a {
            @include xs {
                // clear room for menu
                max-width: calc(100% - 90px);
            }
        }
    }


    .navbar-toggle {
        width: 90px;
        //float: right;
        margin-right: 0;
        margin-top: 10px;
        margin-bottom: 6px;
        padding: 0;
        text-align: right;
        text-transform: uppercase;
        font-size: 16px;
        //@include vertical-center-transform;
    }

    .navbar-nav {
        @include md-and-up {
            margin-top: 7px;
        }

        li {
            margin-left: 10px;
            @include sm {
                margin-left: 4px;
            }
        }
        li > a {
            color: $gray-text-dark;
            font-weight: $font-bold;
            text-transform: uppercase;
            padding: 0 2px 8px;
            letter-spacing: 0.1em;
            font-size: 16px;
            line-height: 20px;
            border-bottom: 8px solid transparent;

            &:hover {
                border-bottom: 8px solid $gray-text-dark;
            }

            @include md-and-up {
                padding: 0 5px 8px;
                font-size: 13px;
            }

            @include lg {
                padding: 0 10px 8px;
                font-size: 16px;
            }

            @include sm {
                font-size: 11px;
                padding-bottom: 4px;
                &:hover {
                    border-bottom: 4px solid $gray-text-dark;
                }
            }
        }

        li.current-menu-item > a {
            border-bottom: 8px solid $link-color;

            &:hover {
                border-bottom: 8px solid $gray-text-dark;
            }
            @include sm {
                border-bottom: 4px solid $link-color;
                // padding-bottom: 4px;
                &:hover {
                    border-bottom: 4px solid $gray-text-dark;
                }
            }
        }


    }
}

// this is a body class that makes the header transparent (except for the ads)
// and positions the navigation on top of a background image
.header-over-hero {

    .main-header {
        background: transparent;
    }

    .header-ads {
        border-bottom: 0;
    }
    .masthead {
        &:after {
            border-bottom: 2px solid rgba(255,255,255,0.4);
        }
    }

    @include sm-and-up {

        .logo-reverse {
            display: inline !important;
        }

        .logo-normal {
            display: none;
        }

        .desktop-header {
            position: relative;

            &,
            a,
            a:hover,
            .navbar-nav li > a {
                color: $white;
            }
            .navbar-nav li a:hover {
                border-bottom: 8px solid $white;
            }

            .navbar-nav li.current-menu-item > a {
                border-color: $white;
            }
        }

        .desktop-header-inner {
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
        }

        .header-subhead {
            color: $white;
        }


    }
}

.light-logo {
    @include sm-and-up {
        .logo-on-light {
            display: inline !important;
        }
        .logo-reverse {
            display: none !important;
        }
    }
}

//
// mobile flyout menu
//

// http://stackoverflow.com/questions/19084168/how-to-keep-panel-height-fixed-with-scroll-if-content-overflow-for-jquery-mobile
.primary-mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    padding: 30px 30px;
    background: rgba($white-off, .98);
    color: $gray-text-dark;
    &.collapsing {
        transition-duration: (.1s);
    }

    .nav-closer {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1000;
        padding: 20px;
        display: inline-block;
        cursor: pointer;

        &,
        &:hover {
            font-size: 24px;
            color: $black;
            text-decoration: none;
        }
    }

    ul {
        padding: 0;
    }

    a {
        color: $gray-text-dark;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
        display: block;
    }
    .header-subhead {
        a {
            font-size: 18px;
            line-height: 22px;
        }

    }

    .close {
        opacity: 1;
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .primary-menu {
        font-weight: $font-bold;
        a {
            text-transform: uppercase;
            letter-spacing: 0.1em;
        }
    }

    .secondary-menu {
        font-weight: $font-semibold;
        padding-top: 30px;
        border-top: 1px solid rgba(0,0,0,0.1);
        margin-top: 30px;
    }

    .controls {
        margin-bottom: $section-padding;
    }

    .social-links {
        margin-bottom: 10px;
        max-width: 480px;

        @include xs {
            a {
                width: 25%;
                float: left;
                text-align: center;
                font-size: 32px;
            }
        }
    }

    .sign-up-nav {
        @include xs {
            clear: both;
            text-align: center;
            margin-top: 30px;
            display: block;
        }
    }

    .input-group {
        // max-width: 480px;

        @include xs {

        }
    }

    .social-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .form-search {
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        input,
        button {
            font-size: 19px;
            background: none;
            // background-color: $white-off;
            color: $gray-text-dark;
            font-style: italic;
            padding-left: 0;
            margin-top: 20px;
        }
        input {
            margin-top: 20px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            float: left;
            width: 80%;
        }

        button {
            position: relative;
            top: 5px;
            transform: rotate(90deg);
            float: right;
            margin-right: 20px;
        }

        input,
        .btn {
            border: 0;
            box-shadow: none;
        }
    }
}
