.component-modal-main {
    background-color: white;

    // when shown
    &:not(.mfp-hide) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 700px;
        margin: 0 auto;
        position: relative;
    }

    &.has-link {
        cursor: pointer;
        transition: background-color 0.3s ease;
        &:hover {
            background-color: lighten($fc-gold-light, 25%);
        }
    }

    &.just-image {
        padding-top: 0;
        background-color: transparent;

        .mfp-close {
            color: $white;
        }
    }

    .mfp-content {
        background-color: white;
    }

    .mfp-close {
        color: $gray-text-dark;
        opacity: 0.7;

        top: -40px;
        right: -5px;
        font-size: 30px;

        @include xs-wide-and-up {
            right: -20px;
        }

        @include md-and-up {
            font-size: 36px;
            right: -40px;
        }

        &:hover {
            opacity: 1;
        }
    }

    .modal-content {
        position: relative;
    }

    .modal-text {
        padding: 30px;
    }

    .fc-wordfly-main {
        margin-top: 20px;
    }
}
