// breakpoint helpers based on bootstrap's breakpoints

@mixin xs {
    @media #{screen} and (max-width: #{$screen-xs-max}) {
        @content;
    }
}

//"Wide mobile" range of xs
@mixin xs-wide {
    @media (min-width: $screen-xs) and (max-width: $screen-sm - 1) {
        @content;
    }
}

@mixin sm {
    @media #{screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin md {
    @media #{screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin lg {
    @media #{screen} and (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// XS wide and up
@mixin xs-wide-and-up {
    @media (min-width: $screen-xs) {
        @content;
    }
}


// inclusive mixins
@mixin sm-and-down {
    @media #{screen} and (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin sm-and-up {
    @media #{screen} and (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md-and-down {
    @media #{screen} and (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin md-and-up {
    @media #{screen} and (min-width: #{$screen-md-min}) {
        @content;
    }
}

// specific
@mixin breakpoint-max($w) {
    @media #{screen} and (max-width: $w) {
        @content;
    }
}

@mixin breakpoint-min($w) {
    @media #{screen} and (min-width: $w) {
        @content;
    }
}
