//== Forms



// specific form

.fc-wordfly-main {

    // mobile, one column
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    label, input, button {
        max-width: 320px;
        width: 100%;
        flex: 1;
    }

    // desktop, one row
    @include xs-wide-and-up {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        align-items: center;
        gap: 10px;

        label, input, button {
            height: 100%;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    br {
        display: none; //zap wp auto br
    }




}
