
.page-template-templte-best-list {

    h1 {
        font-weight: 700;
        line-height: 1.1;
        @include sm-and-up {
            font-size: 48px;
        }
        @include lg {
            font-size: 96px;
        }
    }

    .bl-header-text {
        background-color: $white;
        margin: 75px 0 60px -60px;
        padding: 15px 0;
        @include xs {
            margin-left: -45px;
        }
        @include xs-wide-and-up {
            padding-left: 10px; // prevent serifs of font from being cut off by timeline
        }
        @include md-and-up {
            padding: 20px 0 20px 10px;
        }
    }
    .subhead {
        letter-spacing: -1px;
        font-size: 14px;
        @include sm-and-up {
            font-size: 18px;
        }
    }

    .social-share-vertical {
        float: right;

        @include sm-and-up {
            margin-top: 90px;
        }

        .share-buttons {
            display: flex;
            flex-direction: column;
            gap: 6px;
            @include md-and-up {
                position: relative;
                top: -15px;
            }


        }
    }



    .byline {
        font-size: 14px;
        margin-bottom: 15px;
        @include md-and-up {
            font-size: 21px;
        }
    }

    .best-list-nav {
        margin: 0px 0 45px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: start;
        @include md-and-up {

            margin: 40px 0 55px;
            flex-direction: row;
            justify-content: start;
            gap: 15px;
        }
        @include lg {
            margin: 10px 0 75px;
        }
        a {
            display: block;
            // padding: 15px;
            font-weight: 600;
            position: relative;
            font-size: 14px;
            &:hover {
                color: darken($brand-primary, 10%);
                text-decoration: none;
            }
            @include sm-and-up {
                font-size: 21px;
            }
            @include md-and-up {
                &:not(:last-child){
                    border-right: 1px solid $black;
                    padding-right: 15px;

                }
            }

        }
    }


    .main-blurb {
        line-height: 1.8;
        @include sm-and-up {
            line-height: 1.8;
        }
    }
    .six-up-collage {
        margin-bottom: 30px;

        .collage-thumb {
            visibility: hidden;
            &:hover {
                box-shadow: none;

                img {
                    transform: scale(1.05);
                }
            }
            img {
                transition: $transition-link-hover transform ;
            }
        }
    }

    .bl-content-col {
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        header {
            border-top: 2px solid $fc-gold-light;
            border-left: 2px solid $fc-gold-light;
            padding-left: 30px;
        }
    }

    .best-list-item {
        padding-left: 30px;
        margin-bottom: 20px;
        @include sm-and-up {
            padding-left: 45px;
            padding-bottom: 110px;
        }

        &.layout-img_full_quote {
            min-height: 65vh;
            @include xs-wide-and-up {
                min-height: 100vh;
            }
        }

        .item-index {
            position: relative;
            z-index: 1;
            background-color: $white;
            font-family: $font-serif;
            float: left;
            padding: 10px 0 20px;
            margin-left: -42px;
            font-weight: bold;
            font-size: 48px;
            @include sm-and-up {
                font-size: 72px;
                margin-left: -62px;
            }
        }

        .header {
            margin-left: 30px;
            @include sm-and-up {
                margin-left: 45px;
            }
        }

        .item-title {
            margin-bottom: 5px;
            font-weight: 700;
            letter-spacing: 1px;
            padding-top: 36px;
            z-index: 1;
            position: relative;
            @include sm-and-up {
                padding-top: 48px;
                font-size: 46px;
            }
        }

        .subtitle {
            margin-bottom: 30px;
        }

        .item-quote {
            display: block;
            margin-bottom: 20px;
            color: inherit;
            &:hover {
                text-decoration: none;
                color: inherit;
            }
        }

        blockquote {
            margin-bottom: 20px;
            font-size: 15px;
            position: relative;
            @include md-and-up {
                font-size: 21px;
            }
        }

        .item-text {
            position: relative;
            z-index: 1;
        }

        .quotee, .quote-source, .more, .subtitle  {
            font-size: 14px;
        }

        .more {
            display: table;
            font-weight: 700;
            padding-bottom: 5px;
            margin-bottom: 5px;
            &:hover {
                text-decoration: none;
            }

            i {
                font-size: 8px;
                position: relative;
                bottom: 2px;
                margin-right: 5px;
            }
        }

        .img-link {
            font-weight: 700;
            position: relative;
            z-index: 1;
            display: block;
            img {
                display: block;
                position: relative;
                margin-bottom: 30px;
            }
        }

    }

    .header-banner-image {
        display: block;
        margin-bottom: 15px;
    }
}

// Draws a left border
// Inspired from: https://codepen.io/giana/pen/yYBpVY
.js-border-draw-left {

    // effect setup
    border: 0;
    transition: color 0.25s;
    position: relative; // Required, since we're setting absolute on pseudo-elements
    &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        border: 2px solid transparent;     // strt with invisible border
        width: 0;
        height: 0;
        top: 0;   // And this the bottom & left borders (expands left, then up)
        right: 0;
    }

    // the effect trigger class
    &.border-left-active {
        &::after {
            border-left-color: $fc-gold-light;
            transition: height 1s ease-in 0.25s; // And finally height
            width: 100%;
            height: 100%;
        }
    }
}
