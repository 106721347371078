// Related content inside of a single post body

.snippet-inline {

    &.snippet-full {
        background: $gray;
        padding: $grid-gutter-width;
    }

    &.snippet-right {
        float: right;
        margin: 20px;
        width: 200px;
        border: 1px solid #efefef; // todo: delete, just to show it currently

        @include lg {
            margin-right: -80px;
        }
    }

}
