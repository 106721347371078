// Related content inside of a single post body

.snippet-image-left {

    @include md-and-up {
        display: table;
        width: 100%;
    }
    .thumb {
        margin-bottom: 10px;
        @include sm-and-up {
            width: 35%;
            float: left;
            margin-bottom: 0;
        }
        @include md-and-up {
            width: 50%;
        }
    }
    
    .date {
        margin-bottom: 8px;
        font-size: 13px;
    }

    .snippet-post-type {
        font-size: 11px;
        font-style: italic;
        color: #474747;
        span {
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            letter-spacing: 0.1em;

        }
    }

    .related-text {
        margin-bottom: 10px;
        padding: 20px;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        @include sm-and-up {
            padding: 10px 10px 5px 20px;
            width: 65%;
            float: right;
            margin-bottom: 0;

        }
        @include md-and-up {
            width: 50%;
        }
    }

    .snippet-title {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 0;
        font-style: normal;
        color: $blue;
        margin-bottom: 2px;
        font-weight: $font-light;

        a,
        a:hover {
            color: $blue;
        }
    }

    .snippet-meta {
        color: $gray-text-medium;
        font-size: 13px;
        line-height: 1.2;
        font-style: normal;
        margin-bottom: 10px;
        a {
            color: $red;
        }
        @include xs {
            font-size: 16px;
        }
    }

    .snippet-text {
        font-size: 12px;
        @include xs {
            font-size: 16px;
        }
    }

    // variations

    &.white-bg {
        background: $white;
    }

    .home &, .blog & {
        .snippet-title {
            font-size: 22px;
            line-height: 26px;
            font-weight: $font-light;
        }
    }

    &.related-below-two {
        @include sm {
            .snippet-image {
                width: 100%;
                margin-bottom: 10px;
            }
            .related-text {
                width: 100%;
            }
        }
    }
    .blog & {
        .thumb {
            width: 100%;
        }
        .related-text {
            float: none;
        }
    }
}
