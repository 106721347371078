
.overlay, .overlayed .overlay,  {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}

.overlayed .overlay {
    img+&,
    &+img { //image overlays act as shading screens
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .1);
    }
}

%shadowed {
    box-shadow: 0 0 25px rgba(0, 0, 0, .1);
}

//opinionated starting point to background imaged elements
.bg-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

//using img as background (use on parent tag)
.img-bg {
    position: relative;

    //stretch image and blend with side borders
    overflow: hidden;
    img {
        width: 100%; //beginning value (override as needed, including higher than 100% )
        max-width: initial;
    }
}

.bg-white {
    background-color: $white;
}
