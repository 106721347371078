.main-footer {
    background-color: $white;
    font-size: 12px;

    @include sm-and-up {
        font-size: 14px;
    }

    .footer-content {
        .footer-logo {
            margin-bottom: 20px;

            img {
                width: 100%;
                max-width: 300px;
            }
        }
    }

    .footer-nav-primary,
    .footer-nav-secondary {
        margin-bottom: 20px;

        @include sm-and-up {
            margin-bottom: 10px;
        }

        a {
            margin-left: 5px;
            margin-right: 5px;

            @include sm-and-up {
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .footer-nav-primary {
        a {
            color: $gray-text-dark;
            font-weight: $font-semibold;

            &:hover {
                color: $text-color;
                text-decoration: none;
            }
        }
    }

    .footer-nav-secondary {
        a {
            color: $gray-text-medium;
            font-weight: $font-semibold;

            &:hover {
                color: $gray-text-medium;
                text-decoration: none;
            }
        }
    }

    ul {
        display: inline;
    }
}
