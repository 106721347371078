/**
 * @license
 * MyFonts Webfont Build ID 3052373, 2015-07-01T23:24:18-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: DidotLTPro-Bold by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/didot/pro-bold/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 * Licensed pageviews: 500,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3052373
 *
 * © 2015 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2e9355");


@font-face {font-family: 'DidotLTPro-Bold';src: url('../fonts/didot/2E9355_0_0.eot');src: url('../fonts/didot/2E9355_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/didot/2E9355_0_0.woff2') format('woff2'),url('../fonts/didot/2E9355_0_0.woff') format('woff'),url('../fonts/didot/2E9355_0_0.ttf') format('truetype');}
