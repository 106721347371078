//body { min-height: 100%; }
html {background: $white;}
//
// Responsive image by default
//
img {
    height: auto;
    max-width: 100%;
}

ul {
    list-style-type: none;
    padding: 0;
}

.page-container {
    margin-top: $section-spacing-mobile;

    @include sm-and-up {
        margin-top: $section-spacing;
    }
}

.content-box {
    background-color: $white;
    margin-bottom: $section-spacing-mobile;
    @extend %shadowed;

    @include sm-and-up {
        margin-bottom: $section-spacing;
        padding-bottom: 30px;
    }
}

.content-padded {
    padding: 20px;
}

.ads {
    @include xs {
        text-align: center;
    }
    .ad {
        margin-bottom: 20px;
    }
}

@mixin transition-link() {
    transition: $transition-link;

    &:hover {
        transition: $transition-link-hover;
    }
}

@mixin vertical-center-transform() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
