.home-hero {
    background: $white;
    // max-width: 1600px;
    // margin: 0 auto;

    h3 {
        margin-top: 15px;
    }
    .hero-footer {
        padding-bottom: 15px;
        @include xs {
            width: 94%;
            background-color: #f7f7f7;
            margin: -15px auto 30px;
            padding-bottom: 15px
        }

        @include md-and-up {
            margin-top: -100px;
        }

        .issue-header {
            @include md-and-up {
                color: $white;
            }
        }

        .issue-purchase {
            @include lg {
                margin-top: -200px;
            }
            span {
                @include xs {
                    display: none;
                }
                @include sm {
                    display: none;
                }
            }

    }
    }
}

.sidebar {
    .home-current-issue {
        transition: margin 0.3s;
        position: relative;
        &.current-issue-slide-active {

             // intersectiong sidebar content with slider
            // @include sm {
            //     margin-top: -375px;
            // }
            // @include md-and-up {
            //     margin-top: -365px;
            // }
            // // @include lg {
            // //     margin-top: -365px;
            // // }

        }
        .issue {
            margin-bottom: 15px;
        }

    }
}

.newsletter-and-embed {
    padding: 15px 0;
    @include sm-and-down {
        text-align: center;
        .related-text {
            margin: 10px auto;
        }
    }
    @include md-and-up {
        padding: 30px 0;

    }

    .snippet-image-above, .soundcloud-embed {
        @include xs {
            margin-bottom: 45px;
        }
        @include sm-and-up {
            flex: 1;
        }
    }

    .items {
        @include sm-and-up {
            margin-top: -60px;
            display: flex;
            // column-gap: 50px; //alternate is padding
        }

        .thumb {

        }

        .related-text {
            margin: 0 auto;
        }
        .text-wrap {
            @include sm-and-up{
                padding: 0 50px;
            }
        }

    }
    .featured-newsletter {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .snippet-image {
        max-width: 240px;
    }
    .related-text {
        max-width: 360px;
    }

    .thumb {
        display: block;
    }


    .soundcloud-embed {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        iframe {
            position: relative;
            width: 100%;
            @include xs {
                width: 90%;
            }

            // max-width: 300px;
            max-height: 130px;
            margin-bottom: 5px;
        }

        // when in the middle the embed is a little larger
        &:nth-child(2) iframe {
            @include md-and-up {
                width: calc(100% + 50px);
                left: -25px;
            }
        }
    }

    .text-wrap>h4 {
        color: $blue;
        margin: 10px 0;

        @include md-and-up {
            font-size: 22px;
            line-height: 26px;
        }
    }

    .embed-text {
        h4 {
            color: $blue;
            @include md-and-up {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
}


.page-template-template-home {
    .home-hero {
        .slide {

            // shrink slider in home
            // .hero-image {
            //     .cta {
            //         display: flex;
            //     }
            //     img {
            //         object-fit: cover;
            //         max-height: 320px;
            //     }
            // }
        }
    }
}
