.gallery-rotator {
    position: relative;
    width: 100%;

    img {
        max-height: 80vh;
        display: table;
        margin: 0 auto;
    }

    .direction-nav {
        position: absolute;
        top: calc(50% - 10px);
        z-index: 10px;
        width: 100%;
    }

    .slide {
        position: relative;
        &.img-bg img {
            max-height: initial;
        }
    }

    .slick-arrow {
        color: $brand-primary;
        background-color: transparent;
        border: 0;
        outline: 0;

        i {
            font-size: 36px;
        }
    }

    .slick-initialized .slick-slide {
        display: flex;
    }

    .wp-caption-text {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $black;
        color: $white;
        padding: 5px;
    }

    .slick-prev {

    }

    .slick-next {
        float: right;
    }
}
